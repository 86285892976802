/*
 * @Author: sudo
 * @Date: 2022-03-14 17:42:32
 */
export function trim(str) {
  return String.prototype.trim.call(str);
}

export function isType(arg, type) {
  return Object.prototype.toString.call(arg) === "[object " + type + "]";
}

export function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

//弹窗遮罩，请在浏览器中打开
export function shadeBrowser() {
    var div = document.createElement('div');
    div.style.width="100%";
    div.style.height="100%";
    div.style.position="fixed";
    div.style.margin=0;
    div.style.padding=0;
    div.style.backgroundColor="#000";
    div.style.zIndex="#000";
    div.style.opacity=0.5;
    div.innerHTML = "<div style='margin-top: 20%;text-align: center;color: #fff'>请在浏览器中打开以完成支付宝支付</div>";
    return document.body.appendChild(div);
}

export function parseQuery() {
  const res = {};

  const query = (location.href.split("?")[1] || "")
    .trim()
    .replace(/^(\?|#|&)/, "");

  if (!query) {
    return res;
  }

  query.split("&").forEach(param => {
    const parts = param.replace(/\+/g, " ").split("=");
    const key = decodeURIComponent(parts.shift());
    const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null;

    if (res[key] === undefined) {
      res[key] = val;
    } else if (Array.isArray(res[key])) {
      res[key].push(val);
    } else {
      res[key] = [res[key], val];
    }
  });

  return res;
}

// const VUE_APP_API_URL = process.env.VUE_APP_API_URL || `${location.origin}/api`;
// const VUE_APP_API_URL = "http://new.jym.com/api"; // 测试环境;

// const VUE_APP_API_URL = "https://new.ahjuyimei.com/api"; // 测试机;

 const VUE_APP_API_URL = "https://edu.juyimei.com/api"; // 正式环境
//const VUE_APP_API_URL = "http://jym.com/api"; // 测试机;

const VUE_APP_WS_URL = "ws://119.3.72.64:8082";
export { VUE_APP_API_URL, VUE_APP_WS_URL };