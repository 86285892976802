import request from "@utils/request";

/**
 * 首页
 * @returns {*}
 */
export function getHomeData() {
  return request.get("newindex", {}, { login: false });
}

/**
 * 底部
 * @returns {*}
 */
export function getFooter() {
  return request.get("footer", {}, { login: false });
}

/**
 * 头部
 * @returns {*}
 */
export function getHeader() {
  return request.get("header", {}, { login: false });
}

/**
 * 商城
 * @returns {*}
 */
export function getShopData() {
  return request.get("index", {}, { login: false });
}

/**
 * 商城
 * @returns {*}
 */
export function getStoreCustomer() {
  return request.get("get_store_customer", {}, { login: false });
}

/**
 * 文章 轮播列表
 * @returns {*}
 */
export function getArticleBanner() {
  return request.get("/article/banner/list", {}, { login: false });
}

/**
 * 文章分类列表
 * @returns {*}
 */
export function getArticleCategory() {
  return request.get("/article/category/list", {}, { login: false });
}

/**
 * 文章 热门列表
 * @returns {*}
 */
export function getArticleHotList() {
  return request.get("/article/hot/list", {}, { login: false });
}

/**
 * 文章列表
 * @returns {*}
 */
export function getArticleList(q, cid) {
  return request.get("/article/list/" + cid, q, { login: false });
}

/**
 * 分享
 * @returns {*}
 */
export function getShare() {
  return request.get("/share", {}, { login: false });
}

/**
 * 文章详情
 * @returns {*}
 */
export function getArticleDetails(id) {
  return request.get("/article/details/" + id, {}, { login: false });
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
export function getWechatConfig() {
  return request.get(
    "/wechat/config",
    { url: document.location.href },
    { login: false }
  );
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
export function wechatAuth(code, spread, login_type) {
  return request.get(
    "/wechat/auth",
    { code, spread, login_type },
    { login: false }
  );
}
/**
 * 获取快递公司
 * @returns {*}
 */
export function getLogistics() {
  return request.get("/logistics", {}, { login: false });
}

/**
 * 获取登陆logo
 * @returns {*}
 */
export function getLogo(type) {
  return request.get("/wechat/get_logo", { type: type }, { login: false });
}

/**
 * 获取图片base64
 * @retins {*}
 * */
export function imageBase64(image, code) {
  return request.post(
    "/image_base64",
    { image: image, code: code },
    { login: false }
  );
}

/**
 * 获取关注海报
 * @returns {*}
 */
export function follow() {
  return request.get("/wechat/follow", {}, { login: false });
}

/**
 * 获取城市列表
 * @returns {*}
 */
export function getCity() {
  return request.get("/city_list", {}, { login: false });
}

/**
 * 获取线下课程列表
 * @returns {*}
 */
export function lessonList(params) {
  return request.get("/lesson/list", params, { login: false });
}

/**
 * 获取线下课程列表
 * @returns {*}
 */
export function offlineList(id) {
  return request.get("/lesson/offlinelist", {id: id}, { login: false });
}

/**
 * 获取课程详情
 * @returns {*}
 */
export function lessonDetail(id, kf_id) {
  return request.get("/lesson/detail/"+ id+'/'+ (kf_id || 0), {}, { login: false });
}

/**
 * 课程学习播放时间更新
 * @returns {*}
 */
export function lessonRecord(params) {
  return request.post("/lesson/playrecord", params, { login: false });
}

/**
 * 获取用户实名列表信息
 * @returns {*}
 */
export function lessonCert() {
  return request.get("/lesson/cert", {}, { login: true });
}

/**
 * 实名信息新增
 * @returns {*}
 */
export function certUpdate(params) {
  return request.post("/lesson/cert_update", params, { login: true });
}

/**
 * 实名信息编辑
 * @returns {*}
 */
export function certEdit(cert_id) {
  return request.get("/lesson/cert_get?cert_id="+cert_id, {}, { login: true });
}

/**
 * 线下课程报名页详情
 * @returns {*}
 */
export function detailOffline(id) {
  return request.get("/lesson/detail_offline", {id: id}, { login: true });
}

/**
 * 实名信息删除
 * @returns {*}
 */
export function certDel(id) {
  return request.post("/lesson/cert_del", {cert_id: id}, { login: true });
}

/**
 * 国家地区列表
 * @returns {*}
 */
export function Zone() {
  return request.get("/zone", {}, { login: false });
}

/**
 * 国家地区列表
 * @returns {*}
 */
export function lessonConfirm(params) {
  return request.post("/lesson/confirm", params, { login: true });
}

/**
 * 获取海报推广
 * @returns {*}
 */
export function lessonBill(params) {
  return request.post("/lesson/bill", params, { login: true });
}

/**
 * 课程订单生成
 * @returns {*}
 */
export function createOrder(params) {
  return request.post("/lesson/create_order", params, { login: true });
}

/**
 * 课程支付订单获取
 * @returns {*}
 */
export function getOrder(order_id) {
  return request.post("/lesson/get_order", {order_id: order_id}, { login: true });
}

/**
 * 课程支付
 * @returns {*}
 */
export function orderPay(params) {
  return request.post("/lesson/order_pay", params, { login: true });
}

/**
 * 课程列表
 * @returns {*}
 */
export function orderList(status, limit, page) {
  return request.post("/lesson/orderlist", {status: status, limit: limit, page: page}, { login: true });
}

/**
 * 课程订单详情
 * @returns {*}
 */
export function orderDetail(order_id) {
  return request.post("/lesson/order_detail", {order_id: order_id}, { login: true });
}

/**
 * 课程订单取消
 * @returns {*}
 */
export function orderCancel(order_id) {
  return request.post("/lesson/order_cancel", {order_id: order_id}, { login: true });
}

/**
 * 课程订单删除
 * @returns {*}
 */
export function orderDel(order_id) {
  return request.post("/lesson/order_del", {order_id: order_id}, { login: true });
}

/**
 * 课程跳转
 * @returns {*}
 */
export function getOffline(id) {
  return request.get("/lesson/get_offline?id=" + id, {}, { login: true });
}

/**
 * 课程验证
 * @returns {*}
 */
export function phoneLesson(id) {
  return request.get("/lesson/phone_lesson?id=" + id, {}, { login: true });
}

/**
 * 课程发送验证码
 * @returns {*}
 */
export function orderVerify(params) {
  return request.post("/lesson/order_verify", params, { login: true });
}

/**
 * 品推支付详情
 * @returns {*}
 */
 export function pintuiInfo(order_id) {
  return request.post("/lesson/pinInfo", {order_id: order_id}, { login: true });
}

/**
 * 品推支付
 * @returns {*}
 */
 export function pinPay(order_id) {
  return request.post("/lesson/pinPay", {order_id: order_id}, { login: true });
}
