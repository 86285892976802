<template>
  <div class="index" v-cloak v-if="loadingFinish">
    <!-- <div
      class="follow acea-row row-between-wrapper"
      v-if="followHid && followUrl && !subscribe && isWeixin"
    >
      <div>点击“立即关注”即可关注公众号</div>
      <div class="acea-row row-middle">
        <div class="bnt" @click="followTap">立即关注</div>
        <span class="iconfont icon-guanbi" @click="closeFollow"></span>
      </div>
    </div>-->
    <div class="followCode" v-if="followCode">
      <div class="pictrue">
        <img :src="followUrl" />
      </div>
      <div class="mask" @click="closeFollowCode"></div>
    </div>
<!-- 轮播图 -->
    <div class="banner-wrap">
      <div class="slider-banner banner">
        <swiper :options="swiperOption" v-if="banner.length > 0">
          <swiper-slide v-for="(item, index) in banner" :key="index">
            <img @click="bannerSkip(item.wap_url)" :src="item.pic" />
          </swiper-slide>
          <div class="swiper-pagination paginationBanner" slot="pagination"></div>
        </swiper>
      </div>
    </div>

    <div class="nav acea-row">
      <router-link
        :to="item.wap_url ? item.wap_url : ''"
        class="item"
        v-for="(item, index) in menus"
        :key="index"
      >
        <div class="pictrue">
          <img :src="item.pic" />
        </div>
        <div>{{ item.name }}</div>
      </router-link>
    </div>

    <!-- 直播 -->
    <div class="wrapper live" v-if="live">
      <div class="title acea-row row-between-wrapper" style="padding-bottom: 0">
        <div class="text">
          <div class="name line1">
            <i class="jym-bofang"></i>
            <span v-if="live.playback">&nbsp;直播回放</span>
            <span v-else>
              {{ live.type }}
              <em>·</em>
              {{ live.start_time }}
            </span>
          </div>
        </div>

        <div class="live-container" @click="jumpUrl">
          <div class="img-box">
            <img :src="live.cover_pic" />
          </div>
          <div class="text-box">
            <sup>
              <div class="ell">{{ live.title }}</div>
              <span>{{ live.desc }}</span>
            </sup>
            <button class="btn primary">
              <i class="jym-bofang1"></i>
              {{ live.playback ? '直播回放' : '进入直播间' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- vip -->
    <div class="vip-container bg-white" v-if="vip_content.is_show == 1">
      <router-link :to="`/user/dredgeVip/${vip_content.vipid}`">
        <div class="main">
          <div class="left">
            <p>
              <sup>{{ vip_content.name }}</sup>
              <br />
              <sub>
                {{ vip_content.price }}
                <em>{{ vip_content.more }}</em>
              </sub>
            </p>
          </div>
          <div class="right">
            <button class="btn primary">{{ vip_content.botton }}</button>
          </div>
        </div>
      </router-link>
    </div>
<!-- 最新课程 -->
    <div class="lessonList">
      <div class="wrapper">
        <div class="title acea-row row-between-wrapper">
          <div class="text">
            <div class="name line1">{{ lesson_new_list.name }}</div>
          </div>
        </div>

        <div class="scroll-product">
          <swiper class="swiper-wrapper" :options="swiperScroll">
            <swiper-slide
              v-for="(item, index) in lesson_new_list.list"
              :key="index"
              class="swiper-slide-category"
            >
              <router-link :to="{ path: `/lesson/onlineDetail/${item.id}` }">
                <div class="img-box">
                  <img class="icon" src="@assets/images/icon-new.png" />
                  <img v-lazy="item.images" />
                </div>
                <div class="text-box">
                  <sup>
                    <h4>{{ item.bookname }}</h4>
                    <h6>{{ item.son_num ? `共${item.son_num}节课` : `暂无课程` }}</h6>
                  </sup>
                  <sub>
                    <span class="fl">已学习{{ item.num }}人</span>
                    <span
                      class="gold-num fr"
                    >{{ parseInt(item.price) > 0 ? '￥' + item.price : '免费' }}</span>
                  </sub>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>

        <div class="list-container">
          <div class="title acea-row row-between-wrapper">
            <div class="text">
              <div class="name line1">{{ lesson_put_list.name }}</div>
            </div>
          </div>

          <div class="list-style-2" v-for="(item, index) in lesson_put_list.list" :key="index">
            <router-link :to="{ path: `/lesson/onlineDetail/${item.id}` }">
              <div class="img-box">
                <img class="icon" src="@assets/images/icon-hot.png" />
                <img v-lazy="item.images" />
              </div>
              <div class="text-box">
                <h4>{{ item.bookname }}</h4>
                <h6>{{ item.son_num ? `共${item.son_num}节课` : `暂无课程` }}</h6>
                <sub>
                  <span class="fl">已学习{{ item.num }}人</span>
                  <span class="gold-num fr">{{ parseInt(item.price) > 0 ? '￥' + item.price : '免费' }}</span>
                </sub>
              </div>
            </router-link>
          </div>
        </div>

        <!-- 活动banner -->
        <div class="small-banner-container" v-show="activity.length > 0">
          <div class="slider-banner activity">
            <div v-if="activity.length == 1" >
              <img @click="urlBanner(activity[0].wap_link)" :src="activity[0].pic" />
            </div>
            <div v-else>
              <swiper :options="bannerOption">
                <swiper-slide v-for="(item, index) in activity" :key="index">
                  <img @click="urlBanner(item.wap_link)" :src="item.pic" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- 免费课程 -->
        <div class="list-container">
          <div class="title acea-row row-between-wrapper">
            <div class="text">
              <div class="name line1">{{ lesson_free_list.name }}</div>
            </div>
          </div>

          <div class="list-style-2" v-for="(item, index) in lesson_free_list.list" :key="index">
            <router-link :to="{ path: `/lesson/onlineDetail/${item.id}` }">
              <div class="img-box">
                <!-- <img class="icon" src="@assets/images/icon-vip.png" /> -->
                <img v-lazy="item.images" />
              </div>
              <div class="text-box">
                <h4>{{ item.bookname }}</h4>
                <h6>{{ item.son_num ? `共${item.son_num}节课` : `暂无课程` }}</h6>
                <sub>
                  <span class="fl">已学习{{ item.num }}人</span>
                  <span class="gold-num fr">{{ parseInt(item.price) > 0 ? '￥' + item.price : '免费' }}</span>
                </sub>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- 精品推荐 -->
    <div class="store-product-list">
      <div class="title acea-row row-between-wrapper" style="margin-top: .2rem">
        <div class="text">
          <div class="name line1">{{ store_list.name }}</div>
        </div>
      </div>
      <div class="wrap">
        <div class="container" v-for="(item, index) in store_list.list" :key="index">
          <router-link :to="{ path: `/detail/${item.id}` }">
            <div class="img-box">
              <img v-lazy="item.image" />
            </div>
            <div class="text-box">
              <h4>{{ item.store_name }}</h4>
              <sub>
                <span class="fl gold-num">
                  <em>￥</em>
                  {{ item.price }}
                </span>
              </sub>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div style="height:1.2rem;"></div>
    <Footer :current=1></Footer>
    <!-- 广告弹窗 -->
    <div v-if="viewDialog && index_pop.is_show == 1">
      <mt-popup v-model="popupVisible" popup-transition="popup-fade" position="center">
        <div class="index-pop">
          <i class="jym-close dialog-close" @click="closeDialog"></i>
          <img @click="adverDialog(index_pop.wap_url)" :src="index_pop.pic" />
        </div>
      </mt-popup>
    </div>

  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";
import { getHomeData, follow } from "@api/public";
import { isWeixin } from "@utils/index";
import { openShareAll } from "@libs/wechat";
import Footer from "@components/Footer";
export default {
  name: "Index",
  components: {
    Footer,
    swiper,
    swiperSlide,
  },
  props: {},
  data: function () {
    return {
      footerCurrent:1,
      newGoodsBananr: "",
      isWeixin: isWeixin(),
      followUrl: "",
      subscribe: false,
      followHid: true,
      followCode: false,
      showCoupon: false,
      loadingFinish: false,
      popupVisible: true,
      viewDialog: false,
      banner: [],
      menus: [],
      roll: [],
      activity: [],
      live: {},
      lesson_free_list: {},
      lesson_new_list: {},
      lesson_put_list: {},
      store_list: {},
      vip_content: {},
      index_pop: {},
      info: {
        fastList: [],
        bastBanner: [],
        firstList: [],
        bastList: [],
      },
      likeInfo: [],
      lovely: [],
      swiperOption: {
        pagination: {
          el: ".paginationBanner",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        loop: false,
        speed: 1000,
        preventClicks:false
      },
      bannerOption: {
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        loop: true,
        observer: true,
        preventClicks: false,
        observeParents: true,
      },
      swiperScroll: {
        freeMode: true,
        freeModeMomentum: false,
        slidesPerView: "auto",
        observer: true,
        observeParents: true,
      },
    };
  },
  created() {
    window.location.href = "/testh5/pages/index/index"
    if (!sessionStorage.getItem("viewDialog")) {
      this.viewDialog = true;
    }
    this.setOpenShare();
  },
  mounted: function () {
    let that = this;

    that.$dialog.loading.open();
    getHomeData()
      .then((res) => {
        that.$set(that, "banner", res.data.banner);
        that.$set(that, "menus", res.data.menus);
        that.$set(that, "vip_content", res.data.vip_content);
        that.$set(that, "live", res.data.live[0]);
        that.$set(that, "lesson_free_list", res.data.lessonList.lesson_free_list);
        that.$set(that, "lesson_new_list", res.data.lessonList.lesson_new_list);
        that.$set(that, "lesson_put_list", res.data.lessonList.lesson_put_list);
        that.$set(that, "store_list", res.data.store_list);
        that.$set(that, "activity", res.data.activity);
        that.$set(that, "index_pop", res.data.index_pop[0]);
        that.subscribe = res.data.subscribe;
        that.loadingFinish = true;
        // 直播结束时间超过五个小时显示直接回放
        if(new Date().getTime() - new Date(that.live.end_time).getTime() >= 1000 * 60 * 5) {
          that.$set(that, 'live', Object.assign(that.live, {}, {playback: true}))
        }
        that.$dialog.loading.close();
      })
      .catch((err) => {
        that.$dialog.loading.close();
        that.$dialog.error(err.msg);
      });
    this.getFollow();
  },
  methods: {
    //关闭弹窗
    closeDialog() {
      this.viewDialog = false;
      sessionStorage.setItem("viewDialog", "true");
    },
    closeFollow() {
      this.followHid = false;
    },
    followTap() {
      this.followCode = true;
      this.followHid = false;
    },
    closeFollowCode() {
      this.followCode = false;
      this.followHid = true;
    },
    getFollow() {
      follow()
        .then((res) => {
          this.followUrl = res.data.path;
        })
        .catch(() => {});
    },

    // 直播跳转
    jumpUrl() {
      window.open(this.live.play_url, "_self");
    },
    // banner图跳转
    bannerSkip(url) {
      window.open(url, "_self");
    },
    // 活动banner图跳转
    urlBanner(url) {
      window.open(url);
    },
    // 广告弹窗
    adverDialog(url) {
      window.open(url)
    },
    setOpenShare() {
      if (isWeixin()) {
        var configAppMessage = {
          desc: "聚亿美线上教育平台",
          title: "聚亿美线上平台-首页",
          link: location.href,
          imgUrl: "http://img.juyimei.com/86e0c20200806155459296.jpg"
        };
        openShareAll(configAppMessage);
      }
    },
    popupClose() {},
  },
};
</script>
<style lang="scss" scoped>
.index {
  .index .follow {
    z-index: 100000;
  }
  .mint-popup {
    background: transparent;
    .index-pop {
      width: 5.5rem;
      margin: 0 1rem;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
