import User from "@views/user/User";

export default [
  {
    path: "/user",
    name: "User",
    meta: {
      title: "个人中心",
      keepAlive: true,
      footer: true,
      auth: true
    },
    component: User
  },
  {
    path: "/user/binding",
    name: "BindingPhone",
    meta: {
      title: "绑定手机",
      keepAlive: true,
      backgroundColor: "#fff",
      auth: true
    },
    component: () => import("@views/user/BindingPhone.vue")
  },
  {
    path: "/user/add_address/:id?",
    name: "AddAddress",
    meta: {
      title: "添加收货地址",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/address/AddAddress.vue")
  },
  {
    path: "/user/account",
    name: "UserAccount",
    meta: {
      title: "我的账户",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/UserAccount.vue")
  },
  {
    path: "/user/add_manage",
    name: "AddressManagement",
    meta: {
      title: "地址管理",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/address/AddressManagement.vue")
  },
  {
    path: "/user/poster",
    name: "Poster",
    meta: {
      title: "分享海报",
      keepAlive: false,
      backgroundColor: "#a3a3a3",
      auth: true
    },
    component: () => import("@views/user/promotion/Poster.vue")
  },
  {
    path: "/user/sign",
    name: "Sign",
    meta: {
      title: "签到",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/signIn/Sign.vue")
  },
  {
    path: "/user/sign_record",
    name: "SignRecord",
    meta: {
      title: "签到记录",
      keepAlive: true
    },
    component: () => import("@views/user/signIn/SignRecord.vue")
  },
  {
    path: "/user/cash",
    name: "UserCash",
    meta: {
      title: "提现",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/UserCash.vue")
  },
  {
    path: "/user/audit",
    name: "CashAudit",
    meta: {
      title: "提现审核",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/CashAudit.vue")
  },
  {
    path: "/user/promoter_order",
    name: "PromoterOrder",
    meta: {
      title: "推广人订单",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/PromoterOrder.vue")
  },
  {
    path: "/user/promoter_list",
    name: "PromoterList",
    meta: {
      title: "推广人列表",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/PromoterList.vue")
  },
  {
    path: "/user/user_promotion",
    name: "UserPromotion",
    meta: {
      title: "我的推广",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/UserPromotion.vue")
  },
  {
    path: "/user/bill/:types?",
    name: "UserBill",
    meta: {
      title: "账单明细",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/UserBill.vue")
  },
  {
    path: "/user/cashrecord",
    name: "CashRecord",
    meta: {
      title: "提现记录",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/CashRecord.vue")
  },
  {
    path: "/user/commission",
    name: "CommissionDetails",
    meta: {
      title: "佣金明细",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/CommissionDetails.vue")
  },
  {
    path: "/user/integral",
    name: "Integral",
    meta: {
      title: "积分详情",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/signIn/Integral.vue")
  },
  {
    path: "/user/vip",
    name: "UserVip",
    meta: {
      title: "会员中心",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/UserVip.vue")
  },
  {
    path: "/user/data",
    name: "PersonalData",
    meta: {
      title: "账户设置",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/PersonalData.vue")
  },
  {
    path: "/user/user_coupon",
    name: "UserCoupon",
    meta: {
      title: "我的优惠券",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/coupon/UserCoupon.vue")
  },
  {
    path: "/user/get_coupon",
    name: "GetCoupon",
    meta: {
      title: "领取优惠券",
      keepAlive: true
    },
    component: () => import("@views/user/coupon/GetCoupon.vue")
  },
  {
    path: "/user/user_cash",
    name: "UserCash",
    meta: {
      title: "申请提现",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/UserCash.vue")
  },
  {
    path: "/customer/list/:productId?",
    name: "CustomerList",
    meta: {
      title: "客服列表",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/CustomerList.vue")
  },
  {
    path: "/user/Recharge",
    name: "Recharge",
    meta: {
      title: "余额充值",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/Recharge.vue")
  },
  {
    path: "/user/promoter_rank",
    name: "PromoterRank",
    meta: {
      title: "推广人排行",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/PromoterRank.vue")
  },
  {
    path: "/user/commission/rank",
    name: "CommissionRank",
    meta: {
      title: "佣金排行",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/promotion/CommissionRank.vue")
  },
  {
    path: "/user/suggest",
    name: "suggest",
    meta: {
      title: "我的反馈",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/suggest.vue")
  },
  {
    path: "/user/myattention",
    name: "attention",
    meta: {
      title: "我的关注",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/MyAttention.vue")
  },
  {
    path: "/user/myvip",
    name: "myvip",
    meta: {
      title: "我的VIP",
      keepAlive: true,
      auth: true,
      backgroundColor: "#F7F8F8"
    },
    component: () => import("@views/user/MyVip.vue")
  },
  {
    path: "/user/renewrecord",
    name: "renew-record",
    meta: {
      title: "续费记录",
      keepAlive: true,
      auth: true,
      backgroundColor: "#F7F8F8"
    },
    component: () => import("@views/user/RenewRecord.vue")
  },
  {
    path: "/user/dredgeVip/:id",
    name: "dredgeVip",
    meta: {
      title: "VIP中心",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/dredgeVip.vue")
  },
 
  {
    path: "/user/mylesson",
    name: "mylesson",
    meta: {
      title: "我的课程",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/user/MyLesson.vue")
  },
  {
    path: "/user/siteSign",
    name: "site-sign",
    meta: {
      title: "培训签到",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/siteSign.vue")
  },
  {
    path: "/user/siteCode/:id",
    name: "site-code",
    meta: {
      title: "培训扫码",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/siteCode.vue")
  },
  {
    path: "/user/MyFind",
    name: "my-find",
    meta: {
      title: "我的发现",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/MyFind.vue")
  },
  {
    path: "/user/MyFind",
    name: "my-find",
    meta: {
      title: "我的发现",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/MyFind.vue")
  },
  {
    path: "/user/MyService",
    name: "my-service",
    meta: {
      title: "我的客服",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/user/MyService.vue")
  },
  {
    path: "/user/MyListen",
    name: "my-listen",
    meta: {
      title: "我的听课人",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/MyListen.vue")
  },
  {
    path: "/user/MyShare",
    name: "my-share",
    meta: {
      title: "分享好友",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/MyShare.vue")
  },
  {
    path: "/user/question",
    name: "my-question",
    meta: {
      title: "问卷调查",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/question.vue")
  },
  {
    path: "/user/questionPublic",
    name: "my-question",
    meta: {
      title: "问卷调查",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/questionPublic.vue")
  },
  {
    path: "/user/questionBefore",
    name: "my-question",
    meta: {
      title: "课前问卷调查",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/questionBefore.vue")
  },
  {
    path: "/user/CharacterTest",
    name: "character-test",
    meta: {
      title: "性格测试",
      keepAlive: true,
      auth: true,
      backgroundColor: "#fff"
    },
    component: () => import("@views/user/CharacterTest.vue")
  },
  {
    path: "/user/StaffEvaluation",
    name: "staff-evaluation",
    meta: {
      title: "员工评价",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/StaffEvaluation.vue")
  },
  {
    path: "/user/StaffQuestionnaire",
    name: "staff-StaffQuestionnaire",
    meta: {
      title: "员工评价问卷",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/StaffQuestionnaire.vue")
  },
  {
    path: "/user/BackStaffEvaluation",
    name: "backStaff-evaluation",
    meta: {
      title: "预备核心高管评价",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/BackStaffEvaluation.vue")
  },
  {
    path: "/user/BackStaffQuestionnaire",
    name: "backStaff-StaffQuestionnaire",
    meta: {
      title: "预备核心高管问卷",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/BackStaffQuestionnaire.vue")
  },
  {
    path: "/user/PersonPhotos",
    name: "person-photos",
    meta: {
      title: "用户个人照片",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/PersonPhotos.vue")
  },
  {
    path: "/user/empty",
    name: "user-empty",
    meta: {
      title: "2021品牌战略发布会",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/empty.vue")
  },
  {
    path: "/user/MyConference",
    name: "my-Conference",
    meta: {
      title: "2021品牌战略发布会 现场直播",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/MyConference.vue")
  },
  {
    path: "/user/uploadPersonInfo",
    name: "user-uploadPersonInfo",
    meta: {
      title: "个人信息采集",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/uploadPersonInfo.vue")
  },
  {
    path: "/user/userFace",
    name: "user-Face",
    meta: {
      title: "学员个人信息采集",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/userFace.vue")
  },
  {
    path: "/user/lessonNetwork",
    name: "user-lessonNetwork",
    meta: {
      title: "课程二维码",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/lessonNetwork.vue")
  },
  {
    path: "/user/invoice",
    name: "user-invoice",
    meta: {
      title: "开票服务",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa",
    },
    component: () => import("@views/user/invoice.vue")
  },
  {
    path: "/user/invoiceEdit",
    name: "user-invoiceEdit",
    meta: {
      title: "编辑发票信息",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/invoiceEdit.vue")
  },
  {
    path: "/user/invoiceEditEntp",
    name: "user-invoiceEditEntp",
    meta: {
      title: "编辑发票信息",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/invoiceEditEntp.vue")
  },
  {
    path: "/user/networkQRcode",
    name: "user-networkQRcode",
    meta: {
      title: "课程支付详情",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/networkQRcode.vue")
  },
  {
    path: "/user/networkStatus",
    name: "user-networkStatus",
    meta: {
      title: "课程支付订单",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/networkStatus.vue")
  },
  {
    path: "/user/networkAddress",
    name: "user-networkAddress",
    meta: {
      title: "课程收货地址",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/networkAddress.vue")
  },
  {
    path: "/user/networkStudentList",
    name: "user-networkStudentList",
    meta: {
      title: "学员列表",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/networkStudentList.vue")
  },
  {
    path: "/user/trillAddress",
    name: "user-trillAddress",
    meta: {
      title: "抖音地址",
      keepAlive: true,
      auth: true,
      backgroundColor: "#f7f8fa"
    },
    component: () => import("@views/user/trillAddress.vue")
  }
];
