<template>
  <div class="user">
    <div class="header acea-row row-between-wrapper">
      <div class="user-set">
        <router-link :to="`/user/data`">
          <span
            class="jym-icon-test16 gold-color"
            style="font-size: 0.42rem"
          ></span>
        </router-link>
      </div>
      <div class="picTxt acea-row row-between-wrapper">
        <div class="pictrue">
          <img :src="userInfo.avatar" />
        </div>
        <div class="text">
          <div class="acea-row row-middle">
            <div class="name line1">{{ userInfo.nickname }}</div>
            <div class="member acea-row row-middle btn-bg" v-if="vipinfo.name">
              <!-- <img :src="userInfo.vip_icon" /> -->
              <router-link
                :to="vipinfo.is_pay == 1 ? `user/dredgeVip/${vipinfo.id}` : ''"
                >{{ vipinfo.name }}</router-link
              >
            </div>
          </div>
          <div class="acea-row row-middle student-number">
            学号： {{ PrefixInteger(uid, 8) }}
          </div>
          <!-- <router-link :to="'/user/data'" class="id" v-if="userInfo.phone">
            ID：{{ userInfo.uid || 0
            }}
            <span class="iconfont icon-bianji1"></span>
          </router-link>
          <router-link :to="'/user/binding'" class="binding" v-else>
            <span>绑定手机号</span>
          </router-link>-->
        </div>
      </div>

      <!-- vip -->
      <div class="vip btn-bg" v-show="vipup.length > 0">
        {{ vipup.name || vipinfo.name }} [{{
          vipup.valid_date || vipinfo.valid_date
        }}/{{ vipup.money || vipinfo.money }}元]
        <router-link :to="`/user/dredgeVip/${vipup.id || vipinfo.id}`">
          <button class="btn-black">VIP开通</button>
        </router-link>
      </div>
    </div>

    <div class="my-account">
      <div class="wrapper" style="padding-top: 0">
        <div class="nav acea-row row-middle">
          <router-link class="item" :to="'/user/bill/0'">
            <div class="pictrue">
              <i class="jym-icon-test8"></i>
            </div>
            <div>账单记录</div>
          </router-link>
          <router-link class="item" :to="'/user/bill/1'">
            <div class="pictrue">
              <i class="jym-xiaofeijilu1"></i>
            </div>
            <div>消费记录</div>
          </router-link>
          <router-link class="item" :to="'/user/bill/2'">
            <div class="pictrue">
              <i class="jym-chongzhijilu"></i>
            </div>
            <div>充值记录</div>
          </router-link>
          <router-link class="item" :to="'/user/bill/5'">
            <div class="pictrue">
              <i class="jym-tixian1"></i>
            </div>
            <div>退款记录</div>
          </router-link>
        </div>

        <div class="advert acea-row row-between-wrapper">
          <router-link
            class="item acea-row row-between-wrapper"
            :to="'/user/sign'"
          >
            <img src="@assets/images/user_discount.png" />
          </router-link>
          <router-link
            class="item on acea-row row-between-wrapper"
            :to="'/user/get_coupon'"
          >
            <img src="@assets/images/user_sign.png" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="wrapper">
      <div class="myOrder">
        <div class="title acea-row row-between-wrapper">
          <div>我的订单</div>
          <!-- <router-link :to="'/order/list/'" class="allOrder">
            全部订单
            <span class="iconfont icon-jiantou"></span>
          </router-link>-->
        </div>
        <div class="orderState acea-row row-middle">
          <router-link :to="{ path: '/order/list/' + 0 }" class="item">
            <div class="pictrue">
              <i class="jym-daifukuan1 bold"></i>
              <span
                class="order-status-num"
                v-if="orderStatusNum.unpaid_count > 0"
                >{{ orderStatusNum.unpaid_count }}</span
              >
            </div>
            <div>待付款</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 1 }" class="item">
            <div class="pictrue">
              <i class="jym-daifahuo1"></i>
              <span
                class="order-status-num"
                v-if="orderStatusNum.unshipped_count > 0"
                >{{ orderStatusNum.unshipped_count }}</span
              >
            </div>
            <div>待发货</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 2 }" class="item">
            <div class="pictrue">
              <i class="jym-ziyuan"></i>
              <span
                class="order-status-num"
                v-if="orderStatusNum.received_count > 0"
                >{{ orderStatusNum.received_count }}</span
              >
            </div>
            <div>待收货</div>
          </router-link>
          <!-- <router-link :to="{ path: '/order/list/' + 3 }" class="item">
            <div class="pictrue">
              <i class="jym-shinshopdaipingjia"></i>
              <span
                class="order-status-num"
                v-if="orderStatusNum.evaluated_count > 0"
              >{{ orderStatusNum.evaluated_count }}</span>
            </div>
            <div>待评价</div>
          </router-link> -->
          <router-link :to="'/order/refund_list'" class="item">
            <div class="pictrue">
              <i class="jym-shouhuo_icon_n"></i>
              <span
                class="order-status-num"
                v-if="orderStatusNum.refund_count > 0"
                >{{ orderStatusNum.refund_count }}</span
              >
            </div>
            <div>售后/退款</div>
          </router-link>
        </div>
      </div>

      <div class="myService">
        <div class="title acea-row row-middle">服务中心</div>
        <div class="serviceList acea-row row-middle">
          <!--          <div class="item no-margin">-->
          <!--            <router-link to="/user/user_promotion">-->
          <!--              <i class="jym-tuiguang"></i>-->
          <!--              <div>我的推广</div>-->
          <!--            </router-link>-->
          <!--          </div>-->
          <div class="item no-margin">
            <router-link to="/user/poster">
              <i class="jym-tuiguang"></i>
              <div>分享海报</div>
            </router-link>
          </div>
          <div class="item no-margin">
            <router-link :to="{ path: '/user/account' }" class="item">
              <div class="num">{{ userInfo.now_money || 0 }}</div>
              <div>我的余额</div>
            </router-link>
          </div>
          <div class="item no-margin">
            <router-link :to="'/user/user_coupon'" class="item">
              <div class="num">{{ userInfo.couponCount || 0 }}</div>
              <div>优惠券</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/collection">
              <i class="jym-shoucang"></i>
              <div>我的收藏</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/user/MyLesson">
              <i class="jym-kecheng1"></i>
              <div>我的课程</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/user/MyAttention">
              <i class="jym-chakantieziguanzhu"></i>
              <div>我的关注</div>
            </router-link>
          </div>
          <!--          <div class="item" @click="vipVisible = true">-->
          <!--            <i class="jym-vip"></i>-->
          <!--            <div>我的VIP</div>-->
          <!--          </div>-->
          <div class="item">
            <router-link to="/user/myvip">
              <i class="jym-vip"></i>
              <div>我的VIP</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/user/MyShare">
              <i class="jym-icon-test15"></i>
              <div>分享好友</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/user/MyListen">
              <i class="jym-icon-test37"></i>
              <div>我的听课人</div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="myService">
        <div class="title acea-row row-middle phone">
          <div>客服中心</div>
          <div>
            <i
              class="iconfont icon-dadianhua01"
              style="font-size: 14px; margin-right: 10px"
            ></i>
            <a href="tel:400 696 8687" class="call" style="color: blue"
              >400 696 8687</a
            >
          </div>
        </div>
        <div class="serviceList acea-row row-middle">
          <!-- <div class="item">
            <i class="jym-jishuzhichifuwurexian"></i>
            <div>官方热线<br /><span style="font-size: .2rem">551-888000</span></div>
          </div>-->
          <div class="item">
            <router-link to="/user/suggest">
              <i class="jym-jianyi"></i>
              <div>投诉建议</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/customer/chat">
              <i class="jym-kefu"></i>
              <div>在线客服</div>
            </router-link>
          </div>
          <div class="item" v-show="userInfo.is_kf_qrcode">
            <router-link
              :to="`/lesson/invite?admin_kf_id=${userInfo.kf_id}&offline_id=${userInfo.offline_id}`"
            >
              <i class="jym-yaoqing1"></i>
              <div>客服邀约</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/user/MyService" v-show="userInfo.customer_id">
              <i class="jym-weixin"></i>
              <div>微信客服</div>
            </router-link>
          </div>
          <div class="item" v-show="is_sign">
            <router-link to="/user/siteSign">
              <i class="jym-saoma"></i>
              <div>签到扫码</div>
            </router-link>
          </div>
          <div class="item" v-show="is_teacher == 1">
            <router-link :to="`/community/teacher/${uid}`">
              <i class="jym-faxian"></i>
              <div>我的发现</div>
            </router-link>
          </div>
          <div class="item" v-show="is_network">
            <router-link :to="`/user/lessonNetwork`">
              <i class="jym-kecheng"></i>
              <div>课程二维码</div>
            </router-link>
          </div>
          
          <!-- v-show="userInfo.is_tax" -->
          <div class="item">
            <router-link :to="`/user/invoice`" v-show="userInfo.is_tax">
              <!-- <img src="@/assets/images/invoice.png" alt=""> -->
              <i class="iconfont icon-ticket"></i>
              <div>开票服务</div>
            </router-link>
          </div> 
        </div>
      </div>
    </div>
    <div class="footer-line-height"></div>
    <Footer :current=4></Footer>
    <SwitchWindow
      v-on:changeswitch="changeswitch"
      :switchActive="switchActive"
      :login_type="userInfo.login_type"
    ></SwitchWindow>
    <GeneralWindow
      :generalActive="generalActive"
      @closeGeneralWindow="closeGeneralWindow"
      :generalContent="generalContent"
    ></GeneralWindow>

    <mt-popup v-model="vipVisible" position="center">
      <i class="jym-close dialog-close" @click="vipVisible = false"></i>
      <div class="my-vip">
        <ul>
          <li>
            <b>会员名称</b>
            <b>有效期</b>
          </li>
          <div v-if="vipList.length > 0">
            <li v-for="(item, index) in vipList" :key="index">
              <span>{{ item.name }}</span>
              <span>{{ item.valid_date }}</span>
            </li>
          </div>
          <div v-else>
            <li>
              <span>暂无</span>
              <span>暂无</span>
            </li>
          </div>
        </ul>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getUser } from "@api/user";
import { getWechatConfig } from "@api/public";
import { isWeixin } from "@utils";
import SwitchWindow from "@components/SwitchWindow";
import GeneralWindow from "@components/GeneralWindow";
import { userLevel } from "../../api/user";
import Footer from "@components/Footer";
const NAME = "User";
export default {
  name: NAME,
  components: {
    SwitchWindow,
    GeneralWindow,
    Footer
  },
  props: {},
  data: function () {
    return {
      uid: "",
      is_sign: "",
      is_teacher: "",
      vipList: [],
      vipinfo: {},
      vipup: {},
      userInfo: {},
      orderStatusNum: {},
      is_network: false,
      switchActive: false,
      isWeixin: false,
      generalActive: false,
      vipVisible: false,
      generalContent: {
        promoterNum: "",
        title: "",
      },
    };
  },
  watch: {
    $route(n) {
      if (n.name === NAME) this.User();
    },
  },
  computed: mapGetters(["isLogin"]),
  mounted: function () {
    window.location.href = "/testh5/pages/user/index"
    this.User();
    this.getVip();
    this.isWeixin = isWeixin();
   
  },
  created:function() {
    this.isWeixin = isWeixin();
  },
  methods: {
    // num传入的数字，n需要的字符长度
    PrefixInteger(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },

    changeswitch: function (data) {
      this.switchActive = data;
    },
    User: function () {
      let that = this;
      getUser().then((res) => {
        if(this.isLogin) {
          this.getConfig();
        } 
        that.userInfo = res.data;
        that.uid = res.data.uid;
        that.is_sign = res.data.is_sign;
        that.is_teacher = res.data.is_teacher;
        that.is_network = res.data.is_network;
        that.vipup = res.data.vip.vipup;
        that.vipinfo = res.data.vip.vipinfo;
        that.orderStatusNum = res.data.orderStatusNum;
        this.generalContent = {
          promoterNum: `您未获得VIP会员，<span style="color: #ff8500;">开通VIP</span>即可开通推广权限`,
          title: "您未获得推广权限",
        };

        this.$store.commit("userInfo", res.data);
      });
    },
    goPagey(url) {
      if (!this.userInfo.is_promoter) {
        return (this.generalActive = true);
      }
      this.$router.push({ path: url });
    },
    closeGeneralWindow(msg) {
      this.generalActive = msg;
    },
    getVip() {
      userLevel()
        .then((res) => {
          this.vipList = res.data.list;
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 获取公众号sdk
    getConfig() {
      getWechatConfig()
        .then((res) => {
          console.log(res)
          let data = res.data;
          wx.config({
            debug: data.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: data.jsApiList,
            openTagList: ["wx-open-launch-weapp"], // 跳转小程序时必填
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.user-set {
  width: 100%;
  text-align: right;
  margin-bottom: 0.2rem;
}
.user-set span {
  margin-left: 0.2rem;
}
.footer-line-height {
  height: 1rem;
}
.order-status-num {
  min-width: 0.33rem;
  background-color: #fff;
  color: #daa070;
  border-radius: 15px;
  position: absolute;
  right: -0.14rem;
  top: -0.15rem;
  border: 1px solid #daa070;
  font-size: 0.2rem;
  padding: 0 0.1rem;
  height: 0.36rem;
  line-height: 0.36rem;
}

.pictrue {
  position: relative;
}
.switch-h5 {
  margin-left: 0.2rem;
}
.binding {
  padding: 0.05rem 0.2rem;
  background-color: #ca1f10;
  border-radius: 50px;
  font-size: 0.14rem;
  border: 1px solid #e8695e;
  color: #ffffff;
}
.header > span {
  margin-top: -1rem;
}
.student-number {
  font-size: 0.26rem;
  margin-top: 0.1rem;
}
.mint-popup {
  background: none;
}
.my-vip {
  border-radius: 0.04rem;
  width: 6.5rem;
  padding: 0.3rem;
  max-height: 8rem;
  overflow-y: scroll;
  min-height: 3rem;
}
.my-vip ul li {
  display: flex;
  flex-flow: row nowrap;
  height: auto;
  padding-bottom: 0.1rem;
  line-height: 0.4rem;
  color: white;
  padding-left: 0.5rem;
}
.my-vip b {
  margin-bottom: 0.1rem;
  color: #daa070;
}
.my-vip b,
.my-vip span {
  width: 50%;
}
</style>
