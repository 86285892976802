import Vue from "vue";
import Router from "vue-router";
import module from "./module";
import Index from "@views/home/Index";
import Search from "@views/shop/GoodSearch";
import Category from "@views/shop/GoodsClass";
import ShoppingCart from "@views/shop/ShoppingCart";
import GoodsList from "@views/shop/GoodsList";
import NotDefined from "@views/NotDefined";
import $store from "../store";
import toLogin from "@libs/login";
import Loading from "@views/Loading";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Index",
      meta: {
        title: "首页",
        keepAlive: true,
        menu: true,
        footer: true,
        backgroundColor: "#fff"
      },
      component: Index
    },
    {
      path: "/shop",
      name: "shop",
      meta: {
        title: "商城",
        keepAlive: true,
        footer: true,
        backgroundColor: "#fff"
      },
      component: () => import("@views/shop/index.vue")
    },
    {
      path: "/customer/chat",
      name: "CustomerService",
      meta: {
        title: "客服聊天",
        keepAlive: false,
        auth: true
      },
      component: () => import("@views/user/CustomerService.vue")
    },
    {
      path: "/category/:pid?",
      name: "GoodsClass",
      meta: {
        title: "产品分类",
        keepAlive: true,
        footer: true,
        backgroundColor: "#fff"
      },
      component: Category
    },
    {
      path: "/collection",
      name: "GoodsCollection",
      meta: {
        title: "我的收藏",
        keepAlive: false,
        auth: true,
        backgroundColor: "#fff"
      },
      component: () => import("@views/shop/GoodsCollection.vue")
    },
    {
      path: "/search",
      name: "GoodSearch",
      meta: {
        title: "搜索商品",
        keepAlive: true,
        backgroundColor: "#fff"
      },
      component: Search
    },
    {
      path: "/news_detail/:id",
      name: "NewsDetail",
      meta: {
        title: "新闻详情",
        keepAlive: true,
        backgroundColor: "#fff"
      },
      component: () => import("@views/shop/news/NewsDetail.vue")
    },
    {
      path: "/news_list",
      name: "NewsList",
      meta: {
        title: "新闻",
        keepAlive: true,
        backgroundColor: "#fff"
      },
      component: () => import("@views/shop/news/NewsList.vue")
    },
    {
      path: "/evaluate_list/:id",
      name: "EvaluateList",
      meta: {
        title: "商品评分",
        keepAlive: true,
        auth: true
      },
      component: () => import("@views/shop/EvaluateList.vue")
    },
    {
      path: "/goods_evaluate/:id",
      name: "GoodsEvaluate",
      meta: {
        title: "商品评价",
        keepAlive: true,
        auth: true
      },
      component: () => import("@views/shop/GoodsEvaluate.vue")
    },
    {
      path: "/promotion",
      name: "GoodsPromotion",
      meta: {
        title: "促销单品",
        keepAlive: false
      },
      component: () => import("@views/shop/GoodsPromotion.vue")
    },
    {
      path: "/hot_new_goods/:type",
      name: "HotNewGoods",
      meta: {
        title: "热门榜单",
        keepAlive: false
      },
      component: () => import("@views/shop/HotNewGoods.vue")
    },
    {
      path: "/detail/:id",
      name: "GoodsCon",
      meta: {
        title: "商品详情",
        keepAlive: false
      },
      component: () => import("@views/shop/GoodsCon.vue")
    },
    {
      path: "/shop/storeList/:gonames?",
      name: "StoreList",
      meta: {
        title: "门店列表",
        keepAlive: false
      },
      component: () => import("@views/shop/StoreList.vue")
    },
    {
      path: "/cart",
      name: "ShoppingCart",
      meta: {
        title: "购物车",
        keepAlive: true,
        footer: true,
        auth: true
      },
      component: ShoppingCart
    },
    {
      path: "/goods_list",
      name: "GoodsList",
      meta: {
        title: "商品列表",
        keepAlive: false
      },
      component: GoodsList
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "注册",
        keepAlive: true
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "@views/user/Register.vue")
    },
    {
      path: "/change_password",
      name: "ChangePassword",
      meta: {
        title: "修改密码",
        keepAlive: true,
        backgroundColor: "#fff",
        auth: true
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "@views/user/ChangePassword.vue")
    },
    {
      path: "/retrieve_password",
      name: "RetrievePassword",
      meta: {
        title: "找回密码",
        keepAlive: true
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@views/user/RetrievePassword.vue"
        )
    },
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "登录",
        keepAlive: true
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "@views/user/Login.vue")
    },
    ...module,
    {
      path: "/auth/:url",
      name: "Loading",
      meta: {
        title: " 加载中",
        keepAlive: true
      },
      component: Loading
    },
    {
      path: "*",
      name: "NotDefined",
      meta: {
        title: "页面找不到",
        keepAlive: true,
        home: false,
        backgroundColor: "#F4F6FB"
      },
      component: NotDefined
    },
    {
      path: "/live",
      name: "liveList",
      meta: {
        title: "直播列表",
        footer: true,
        keepAlive: false
      },
      component: () => import("@views/Live/LiveList.vue")
    },
    {
      path: "/lesson/list",
      name: "lesson-list",
      meta: {
        title: "线下课程",
        keepAlive: false,
        footer: true,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/list.vue")
    },
    {
      path: "/lesson/offline/:id",
      name: "lesson-offline",
      meta: {
        title: '线下课程',
        keepAlive: false,
        footer: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/offline.vue")
    },
    {
      path: "/lesson/online/:id",
      name: "NewsDetail",
      meta: {
        title: "线上课程",
        menu: true,
        keepAlive: false,
        footer: true
      },
      component: () => import("@views/Lesson/online.vue")
    },
    {
      path: "/lesson/online",
      name: "online",
      meta: {
        title: "线上课程",
        keepAlive: false,
        footer: true
      },
      component: () => import("@views/Lesson/online.vue")
    },
    {
      path: "/lesson/verifyLesson/:id",
      name: "lesson-verifyLesson",
      meta: {
        title: '验证课程',
        keepAlive: false,
        footer: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/verifyLesson.vue")
    },
    {
      path: "/lesson/apply/:id",
      name: "lesson-apply",
      meta: {
        title: "课程报名",
        keepAlive: false,
        footer: false
      },
      component: () => import("@views/Lesson/apply.vue")
    },
    {
      path: "/lesson/personList",
      name: "person-list",
      meta: {
        title: "上课管理人",
        keepAlive: false,
        footer: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/personList.vue")
    },
    {
      path: "/lesson/completeInfo",
      name: "complete-info",
      meta: {
        title: "完善信息",
        keepAlive: false,
        footer: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/completeInfo.vue")
    },
    {
      path: "/lesson/applyDetail",
      name: "apply-detail",
      meta: {
        title: "课程报名",
        keepAlive: false,
        footer: false
      },
      component: () => import("@views/Lesson/applyDetail.vue")
    },
    {
      path: "/lesson/pay",
      name: "lesson-pay",
      meta: {
        title: "订单详情",
        keepAlive: false
      },
      component: () => import("@views/Lesson/pay.vue")
    },
    {
      path: "/lesson/onlineDetail/:id",
      name: "online-detail",
      meta: {
        title: "线上课程详情",
        keepAlive: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/onlineDetail.vue")
    },
    {
      path: "/lesson/Detail/:id",
      name: "lesson-detail",
      meta: {
        title: "课程跳转详情",
        keepAlive: false,
        backgroundColor: "#fff"
      },
      component: () => import("@views/Lesson/Detail.vue")
    },
    {
      path: "/lesson/status",
      name: "pay-status",
      meta: {
        title: "支付状态",
        keepAlive: false
      },
      component: () => import("@views/Lesson/status.vue")
    },
    {
      path: "/alipay",
      name: "alipay",
      meta: {
        title: "支付",
        keepAlive: false
      },
      component: () => import("@views/alipay.vue")
    },
    {
      path: "/lesson/orderDetail/:id",
      name: "order-detail",
      meta: {
        title: "订单详情",
        keepAlive: false
      },
      component: () => import("@views/Lesson/orderDetail.vue")
    },
    {
      path: "/lesson/onlineApply/:id",
      name: "online-apply",
      meta: {
        title: "确认下单",
        keepAlive: false
      },
      component: () => import("@views/Lesson/onlineApply.vue")
    },
    {
      path: "/lesson/invite",
      name: "invite",
      meta: {
        title: "客服邀请",
        keepAlive: false
      },
      component: () => import("@views/Lesson/invite.vue")
    },
    {
      path: "/lesson/myInvited",
      name: "myInvited",
      meta: {
        title: "客服邀请",
        keepAlive: false
      },
      component: () => import("@views/Lesson/MyInvited.vue")
    },
    {
      path: "/demo",
      name: "demo",
      meta: {
        title: "测试",
        keepAlive: false
      },
      component: () => import("@views/demo.vue")
    },
    {
      path: "/pinPay",
      name: "pin-pay",
      meta: {
        title: "定金支付",
        keepAlive: false
      },
      component: () => import("@views/pinPay.vue")
    }
  ],
  scrollBehavior(to, from) {
    from.meta.scrollTop = window.scrollY;
    return { x: 0, y: to.meta.scrollTop || 0 };
  }
});

const { back, replace } = router;

router.back = function() {
  this.isBack = true;
  back.call(router);
};
router.replace = function(...args) {
  this.isReplace = true;
  replace.call(router, ...args);
};

router.beforeEach((to, form, next) => {
  const { title, backgroundColor, menu, footer, home, auth } = to.meta;
  // console.log(to.name, form.name);
 
  if (auth === true && !$store.state.app.token) {
    if (form.name === "Login") return;
    return toLogin(true, to.fullPath);
  }
  document.title = "聚亿美-" + (title || process.env.VUE_APP_NAME || "聚亿美商城");
  //判断是否显示导航栏
  menu === true ? $store.commit("SHOW_MENU") : $store.commit("HIDE_MENU");

  //判断是否显示底部导航
  footer === true ? $store.commit("SHOW_FOOTER") : $store.commit("HIDE_FOOTER");

  //控制悬浮按钮是否显示
  home === false ? $store.commit("HIDE_HOME") : $store.commit("SHOW_HOME");

  $store.commit("BACKGROUND_COLOR", backgroundColor || "#F5F5F5");

  if (auth) {
    $store.dispatch("USERINFO").then(() => {
      next();
    });
  } else next();
});

export default router;
