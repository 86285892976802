<template>
  <div
    class="nav-wrap"
    v-if="headerList.length > 0"
  >
    <div class="scroll-nav">
      <swiper class="swiper-wrapper" :options="swiperScroll">
        <swiper-slide
          v-for="(item, index) in headerList"
          :key="index"
          class="swiper-slide-nav"
        >
          <router-link :to="{
              path: item.wap_url
            }">
            <div
              class="nav-title"
              :class="[item.wap_url == $route.path ? 'nav-title-active' : '']"
            >{{ item.name }}</div>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { getHeader } from "@api/public";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";

export default {
  name: "menu",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      headerList: [],
      swiperScroll: {
        freeMode: true,
        slideToClickedSlide: true,
        freeModeMomentum: false,
        slidesPerView: "auto",
        observer: true,
        observeParents: true
      }
    };
  },
  mounted() {
    getHeader().then(res => {
      this.headerList = res.data.site_nav
    })
  },
};
</script>

<style lang="scss" scoped>
.nav-wrap {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 1rem;
  .scroll-nav {
    white-space: nowrap;
    overflow: hidden;
    background: white;
    .swiper-slide-nav {
      display: inline-block;
      padding: 0 .4rem;
      width: auto !important;
      > a {
        display: block;
        .nav-title {
          text-align: center;
          font-size: 0.28rem;
          line-height: 1rem;
          position: relative;
        }
        .nav-title-active {
          position: relative;
          font-size: 0.32rem;
          font-weight: 700;
        }
        .nav-title-active::before {
          height: 0.08rem;
          width: 0.3rem;
          background: black;
          border-radius: 0.04rem;
          position: absolute;
          left: 50%;
          margin-left: -.15rem;
          bottom: 0.2rem;
          display: block;
          content: "";
        }
      }
    }
  }
}
</style>
