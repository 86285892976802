import request from "@utils/request";
import requestws from "@utils/requestws";

/**
 * 用户登录
 * @param data object 用户账号密码
 */
export function login(data) {
  return request.post("/login", data, { login: false });
}

/**
 * 用户手机号登录
 * @param data object 用户手机号 也只能
 */
export function loginMobile(data) {
  return request.post("/login/mobile", data, { login: false });
}

/**
 * 用户发送验证码
 * @param data object 用户手机号
 */
export function registerVerify(data) {
  return request.post("/register/verify", data, { login: false });
}

/**
 * 用户手机号注册
 * @param data object 用户手机号 验证码 密码
 */
export function register(data) {
  return request.post("/register", data, { login: false });
}

/**
 * 用户手机号修改密码
 * @param data object 用户手机号 验证码 密码
 */
export function registerReset(data) {
  return request.post("/register/reset", data, { login: false });
}

/*
 * 领取优惠券列表
 * */
export function getCoupon(q) {
  return request.get("/coupons", q, { login: false });
}

/*
 * 点击领取优惠券
 * */
export function getCouponReceive(id) {
  return request.post("/coupon/receive", { couponId: id }, { login: true });
}

/*
 * 批量领取优惠券
 * */
export function couponReceiveBatch(couponId) {
  return request.post("/coupon/receive/batch", { couponId });
}

/*
 * 我的优惠券
 * */
export function getCouponsUser(type) {
  return request.get("/coupons/user/" + type);
}

/*
 * 个人中心
 * */
export function getUser() {
  return request.get("/user");
}

/*
 * 用户信息
 * */
export function getUserInfo() {
  return request.get("/userinfo");
}

/*
 * 个人中心(功能列表)
 * */
export function getMenuUser() {
  return request.get("/menu/user");
}

/*
 * 地址列表
 * */
export function getAddressList(data) {
  return request.get("/address/list", data || {});
}

/*
 * 删除地址
 * */
export function getAddressRemove(id) {
  return request.post("/address/del", { id: id });
}

/*
 * 设置默认地址
 * */
export function getAddressDefaultSet(id) {
  return request.post("/address/default/set", { id: id });
}

/*
 * 获取默认地址
 * */
export function getAddressDefault() {
  return request.get("/address/default");
}

/*
 * 获取单个地址
 * */
export function getAddress(id) {
  return request.get("/address/detail/" + id);
}

/*
 * 修改 添加地址
 * */
export function postAddress(data) {
  return request.post("/address/edit", data);
}

/*
 * 获取收藏产品
 * */
export function getCollectUser(page, limit, type) {
  return request.get("/collect/user", { page: page, limit: limit, type: type });
}

/*
 * 批量收藏产品
 * */
export function postCollectAll(data) {
  return request.post("/collect/all", data);
}

/*
 * 添加收藏产品
 * */
export function getCollectAdd(id, category) {
  return request.post("collect/add", { id: id, category: category });
}

/*
 * 取消收藏产品
 * */
export function getCollectDel(id, category) {
  return request.post("collect/del", { id: id, category: category });
}

/*
 * 签到配置
 * */
export function getSignConfig() {
  return request.get("/sign/config");
}

/*
 * 签到里的签到列表
 * */
export function getSignList(page, limit) {
  return request.get("/sign/list", { page: page, limit: limit });
}

/*
 * 签到列表
 * */
export function getSignMonth(page, limit) {
  return request.get("/sign/month", { page: page, limit: limit });
}

/*
 * 签到用户信息
 * */
export function postSignUser(sign) {
  return request.post("/sign/user", sign);
}

/*
 * 签到
 * */
export function postSignIntegral(sign) {
  return request.post("/sign/integral", sign);
}

/*
 * 推广数据
 * */
export function getSpreadInfo() {
  return request.get("/commission");
}

/*
 * 推广人列表
 * */
export function getSpreadUser(screen) {
  return request.post("/spread/people", screen);
}

/*
 * 推广人订单
 * */
export function getSpreadOrder(where) {
  return request.post("/spread/order", where);
}

/*
 * 资金明细（types|0=全部,1=消费,2=充值,3=返佣,4=提现）
 * */
export function getCommissionInfo(q, types) {
  return request.get("/spread/commission/" + types, q);
}

/*
 * 积分记录
 * */
export function getIntegralList(q) {
  return request.get("/integral/list", q);
}

/*
 * 提现银行
 * */
export function getBank() {
  return request.get("/extract/bank");
}

/*
 * 提现申请
 * */
export function postCashInfo(cash) {
  return request.post("/extract/cash", cash);
}

/*
 * 会员中心
 * */
export function getVipInfo() {
  return request.get("/user/level/grade");
}

/*
 * 会员等级任务
 * */
export function getVipTask(id) {
  return request.get("/user/level/task/" + id);
}

/*
 * 资金统计
 * */
export function getBalance() {
  return request.get("/user/balance");
}

/*
 * 活动状态
 * */
export function getActivityStatus() {
  return request.get("/user/activity", {}, { login: false });
}

/*
 * 活动状态
 * */
export function getSpreadImg() {
  return request.get("/spread/banner");
}

/*
 * 用户修改信息
 * */
export function postUserEdit(data) {
  return request.post("/user/edit", data);
}

/*
 * 用户修改信息
 * */
export function getChatRecord(data) {
  return requestws.get("/message/list", data);
}

/*
 * 用户修改信息
 * */
export function serviceList() {
  return request.get("user/service/list");
}

/*
 * 公众号充值
 * */
export function rechargeWechat(data) {
  return request.post("/recharge/wechat", data);
}

/*
 * 退出登录
 * */
export function getLogout() {
  return request.get("/logout");
}

/*
 * 绑定手机号
 * */
export function bindingPhone(data) {
  return request.post("binding", data);
}

/*
 * h5切换公众号登陆
 * */
export function switchH5Login() {
  return request.post("switch_h5", { from: "wechat" });
}
/*
 * 获取推广人排行
 * */
export function getRankList(q) {
  return request.get("rank", q);
}
/*
 * 获取佣金排名
 * */
export function getBrokerageRank(q) {
  return request.get("brokerage_rank", q);
}
/**
 * 检测会员等级
 */
export function setDetection() {
  return request.get("user/level/detection");
}
/**
 * 充值金额选择
 */
export function getRechargeApi() {
  return request.get("recharge/index");
}
/**
 * 验证码key
 */
export function getCodeApi() {
  return request.get("verify_code", {}, { login: false });
}

/*
 * 获取反馈列表
 * */
export function feedbackList(data) {
  return request.get("/feedback/list", data, { login: true });
}

/*
 * 获取反馈详情
 * */
export function feedbackDetail(id) {
  return request.get("/feedback/detail/" + id, {}, { login: true });
}

/*
 * 提交反馈
 * */
export function feedbackSubmit(data) {
  return request.post("/feedback/feedback", data, { login: true });
}

/*
 * 我的关注
 * */
export function myCare(page, limit) {
  return request.get("/community/my_care/"+ page + '/'+ limit, {} , { login: true });
}

/*
 * 发现收藏
 * */
export function communityCollect(page, limit) {
  return request.get("/community/my_collect/"+ page + '/'+ limit, {} , { login: true });
}

/*
 * VIP详情页
 * */
export function vipDetail(id) {
  return request.get("/vipdetail", {id: id} , { login: true });
}
// VIP续费
export function VipRenewal(params) {
  return request.get("/vipdetail", params , { login: true });
}


/*
 * VIP支付订单获取
 * */
export function vipOrder(order_id) {
  return request.post("/vip_order", {order_id: order_id} , { login: true });
}

/*
 * VIP订单生成
 * */
export function vipCreate(id,type) {
  return request.post("/vip_create", {id: id,type:type} , { login: true });
}
// 续费订单生成
export function vipReCreate(params) {
  return request.post("/vip_create", params , { login: true });
}

/*
 * VIP支付
 * */
export function vipPay(params) {
  return request.post("/vip_pay", params , { login: true });
}

/*
 * 线下课程站点签到列表
 * */
export function lessonSign(params) {
  return request.post("/lesson/sign", params , { login: true });
}

/*
 * 线下课程站点签到记录
 * */
export function signLog(sid) {
  return request.post("/lesson/sign_log", {sid: sid} , { login: true });
}

/*
 * 线下课程站点签到退款
 * */
export function sign_refund(params) {
  return request.post("/lesson/sign_refund", params , { login: true });
}

/*
 * 线下课程站点是否住店
 * */
export function sign_hotel(params) {
  return request.post("/lesson/sign_hotel", params , { login: true });
}

/*
 * 上传图片
 * */
export function uploadImages(params) {
  return request.post("/upload/image", params , { login: true });
}

/*
 * 发布内容
 * */
export function publish(params) {
  return request.post("/community/publish", params , { login: true });
}

/*
 * 修改听课地点
 * */
export function orderSite(id) {
  return request.post("/lesson/order_site", {id: id} , { login: true });
}

/*
 * 保存听课地点
 * */
export function orderSiteSave(id, sid) {
  return request.post("/lesson/order_site_save", {id: id, sid: sid} , { login: true });
}

/*
 * 获取当前用户会员等级列表
 * */
export function userLevel() {
  return request.get("/user/level", {} , { login: true });
}

/*
 * 获取VIP客服
 * */
export function userCustomer() {
  return request.get("/user/customer", {} , { login: true });
}

/*
 * 密训课问卷调查
 * */
export function questionLesson() {
  return request.get("/lesson/question_lesson", {} , { login: true });
}

export function questionLessonPublic(type) {
  return request.get("/lesson/question_lesson?type=" + type, {} , { login: true });
}


/*
 * 密训课问卷调查提交
 * */
export function questionnaire(type, params) {
  return request.post("/lesson/questionnaire/" + type, params, { login: true });
}

/**
 * 公开课问卷调查提交
 * @param params
 * @returns {*}
 */
export function questionPublic(params) {
  return request.post("/lesson/question_public_save", params, { login: true });
}

/**
 * 密训课前问卷调查提交
 * @param params
 * @returns {*}
 */
export function questionnaireBefore(params) {
  return request.post("/lesson/question_before_save", params, { login: true });
}


/* 性格测试查询
* */
export function characterSelect() {
  return request.post("/character/select", {} , { login: true });
 }
 
 /*
 * 性格测试提交
 * */
 export function characterSave( params) {
  return request.post("/character/save", params, { login: true });
 }
 
/*
 * 获取上传文件token
 * */
export function getQiniuToken() {
  return request.get("/community/get_qiniu_token", {}, { login: true });
}

/*
 * 生成海报
 * */
export function shareBill() {
  return request.get("/share_bill", {}, { login: true });
}

/*
 * 2021品牌战略发布会海报
 * */
export function conferenceBill() {
  return request.get("/share_activity_bill", {}, { login: true });
}

/*
 * 取消退款
 * */
export function refundCancel(uni) {
  return request.post("/order/refund/cancel", {uni: uni}, { login: true });
}

/*
 * 修改发货地址
 * */
export function addressChange(params) {
  return request.post("/order/address/change", params, { login: true });
}


/*
 * 检查员工是否绑定
 * */
export function isStaff(params) {
  return request.post("/staff/is_staff", params, { login: true });
}

/*
 * 绑定员工信息
 * */
export function isStaffBind(params) {
  return request.post("/staff/is_staff_bind", params, { login: true });
}

/**
 * 绑定员工列表
 */
export function StaffList(params) {
  return request.post("/staff/staff_list", params, { login: true });
}
/**
 * 学员人脸信息采集
 */
export function userFace(params) {
  return request.get("/user/face_info", params, { login: true });
}

/**
 * 预备核心高管名单
 */
export function staffReadyList(params) {
  return request.post("/staff/staff_ready_list", params, { login: true });
}

/**
 * 员工评价提交
 */
export function staffVote(params) {
  return request.post("/staff/staff_vote", params, { login: true });
}

/**
 * 用户头像采集
 */
export function imageFace(params) {
  return request.post("/upload/image_face", params, { login: true });
}

/**
 * 课程二维码列表
 */
export function lessonPhoneList() {
  return request.get("/lesson/lesson_phone_list", {}, {login: true});
}

/**
 * 课程二维码生成海报
 */
export function studentpaybill(params) {
  return request.post("/student/pay_bill", params, {login: true});
}


/**
 * 课程二维码创建订单
 */
export function lessonCreatePhone(params) {
  return request.get("/student/lesson_create_phone", params, {login: true});
}


/**
 * 课程二维码获取订单
 */
export function studentGetOrder(id) {
  return request.get("/student/get_order", {id: id}, {login: true});
}

/**
 * 课程二维码支付
 */
export function studentPay(params) {
  return request.post("/student/pay", params, {login: true});
}

/**
 * 课程地址信息
 */
export function studentGetAddress(type, id) {
  return request.get("/student/get_address", {type: type, id: id}, {login: true});
}

/**
 * 课程地址保存
 */
export function studentAddressSave(id, type, params) {
  return request.post("/student/address_save?id="+id+'&type='+type, params, {login: true});
}

/**
 * 学员列表
 */
export function studentList(params) {
  return request.get("/student/student_list", params, {login: true});
}

/**
 * 学员列表编辑uid
 */
export function studentEditUid(id, uid) {
  return request.post(`/student/edit_uid?id=${id}`, {uid: uid}, {login: true});
}

/**
 * 抖音地址保存
 */
export function studentCreateDouyin(params) {
  return request.post("/student/create_douyin", params, {login: true});
}

/**
 * 获取发票列表
 */
export function invoiceList() {
  return request.get("/tax/get_tax_list", {}, { login: true });
}

/**
 * 提交发票申请
 */
 export function invoiceInfo(params) {
  return request.post("/tax/set_tax_info", params, { login: true });
}

/**
 * 续费记录
 */
export function renewRecord() {
  return request.get("/user/level_renew", {}, { login: true });
}

/**
 * 提交续费收货地址
 */
 export function renewRecordAssress(params) {
  return request.post("/user/level_express", params, { login: true });
}
/**
 * 邀约记录列表
 */
 export function myInviteList(params) {
  return request.get("/lesson/my_invited",params, {login: true});
}