<template>
  <div id="footer" class="acea-row row-middle">
    <!-- <router-link
      :to="item.wap_url"
      class="item"
      :class="{ on: item.wap_url === $route.path }"
      v-for="(item, index) in footerMenu"
      :key="index"
    >
      <div
        class="iconfont"
        :class="
          item.icon1 + ' ' + (item.wap_url === $route.path ? item.icon2 : '')
        "
      ></div>
      <div>{{ item.name }}</div>
    </router-link> -->
    <div class="item" @click="changeTab(1)">
      <div :class="current == 1 ? 'activeTabbar' : ''">
        <div class="iconfont icon-index"></div>
        <div>首页</div>
      </div>
    </div>
    <div class="item" @click="changeTab(2)">
      <div :class="current == 2 ? 'activeTabbar' : ''">
        <div class="iconfont icon-lesson"></div>
        <div>课程</div>
      </div>
    </div>
    <!-- 跳小程序 -->
       <div class="item" v-if="isWeixin &&  isLogin">
         <wx-open-launch-weapp
           class="openweapp"
           id="launch-btn"
           username="gh_5de73a9a64b3"
           path="subA/plan/learn_plan/index"
         >
           <!-- <script type="text/wxtag-template">
             <style>
               .btn {display: flex;flex-direction: column;align-items: center;justify-content: center;}
                .btn span {font-size:10px;color:#aaaaaa}
               .btn img {width:20px;height:22px;margin-bottom:4px;margin-top:4px;}
             </style>
             <div class="btn">
               <img src="https://img.juyimei.com/H5/user/play_study.png">
               <span>云端大课堂</span>
             </div>
           </script> -->
         </wx-open-launch-weapp>
       </div>
    <div class="item" @click="changeTab(3)">
      <div :class="current == 3 ? 'activeTabbar' : ''">
        <div class="iconfont icon-shop"></div>
        <div>商城</div>
      </div>
    </div>
    <div class="item" @click="changeTab(4)">
      <div :class="current == 4 ? 'activeTabbar' : ''">
        <div class="iconfont icon-my"></div>
        <div>我的</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFooter, getWechatConfig } from "@api/public";
import { isWeixin } from "@utils";
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      isWeixin: isWeixin(),
      footerMenu: [],
      newCurrent: "",
    };
  },
  props: {
    current: {
      type: Number,
      default: 0,
    },
  },
  // watch: {
  //   current(newVal, oldVal) {
  //     this.newCurrent = newVal;
  //   },
  // },
  computed: mapGetters(["isLogin"]),
  created() {
    this.newCurrent = this.current;
    //this.getFooterMenu();
    if(this.isLogin) {
      this.getConfig()
    }
   
  },
  methods: {
    // 获取公众号sdk
    getConfig() {
      getWechatConfig()
        .then((res) => {
          console.log(res);
          let data = res.data;
          wx.config({
            debug: data.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: data.jsApiList,
            openTagList: ["wx-open-launch-weapp"], // 跳转小程序时必填
          });
        })
        .catch((err) => {});
    },
    getFooterMenu() {
      let that = this;

      getFooter().then((res) => {
        that.footerMenu = res.data.footerList;
      });
    },
    changeTab(value) {
      if (this.newCurrent == value) return;
      this.newCurrent = value;
      if (this.newCurrent == 1) {
        this.$router.push("/");
      }
      if (this.newCurrent == 2) {
        this.$router.push("/lesson/list");
      }
      if (this.newCurrent == 3) {
        this.$router.push("/shop");
      }
      if (this.newCurrent == 4) {
        this.$router.push("/user");
      }
    },
  },
};
</script>
<style scoped>
.activeTabbar {
  color: #daa070;
}
</style>
