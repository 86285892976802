export default [
  {
    path: "/community",
    name: "community-index",
    meta: {
      title: "发现",
      footer: true,
      keepAlive: false
    },
    component: () => import("@views/community/list.vue")
  },
  {
    path: "/community",
    name: "community-more",
    meta: {
      title: "发现更多",
      keepAlive: true,
      auth: false
    },
    component: () => import("@views/community/more.vue")
  },
  {
    path: "/community/detail/:id",
    name: "community-detail",
    meta: {
      title: "发现详情",
      keepAlive: true,
      footer: false,
      backgroundColor: "#fff"
    },
    component: () => import("@views/community/detail.vue")
  },
  {
    path: "/community/more",
    name: "community-more",
    meta: {
      title: "关注更多",
      keepAlive: true,
      footer: false,
      backgroundColor: "#fff"
    },
    component: () => import("@views/community/more.vue")
  },
  {
    path: "/community/teacher/:id",
    name: "community-teacher",
    meta: {
      title: "",
      keepAlive: true,
      footer: false
    },
    component: () => import("@views/community/teacher.vue")
  }
];
